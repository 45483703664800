import React, { useEffect } from 'react';
import {
  House, Briefcase, Banknote, Users, FileText,
  Building, FilePlus, FileCheck2, User, UserCheck, Lock
} from 'lucide-react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import SidebarNavLink from './SidebarNavLink';
import { fetchPermissions } from "../redux/permissionsSlice";
import { useDispatch, useSelector } from 'react-redux';

const Sidebar = ({ onToggleCollapse, isCollapsed, isRTL }) => {

  const role = JSON.parse(localStorage.getItem("user"))?.roleId; // Retrieve role from localStorage
  console.log("role", role);

  const handleToggleCollapse = () => {
    onToggleCollapse(!isCollapsed);
  };

  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.permissions);
  console.log("sidebar permission", permissions);


  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  return (
    <div
      className={`relative top-0 p-4 border-gray-600 dark:border-r shadow-md transition-width duration-500 ${isCollapsed ? 'w-20' : 'w-72'} ${
        isCollapsed ? 'min-h-0' : 'min-h-full'
      }`}
    >
      <div className="flex flex-col justify-between h-full">
        {/* Top Section */}
        <div>
          <nav className="space-y-2">
            <SidebarNavLink to="/" icon={House} label="Dashboard" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/program-type" icon={Briefcase} label="Program Type" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/banks" icon={Banknote} label="Banks" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/bank-employee" icon={Banknote} label="Bank Employee" isCollapsed={isCollapsed} />
            {/* <SidebarNavLink to="/departments" icon={Building} label="Departments" isCollapsed={isCollapsed} /> */}
            <SidebarNavLink to="/loan-application" icon={FileCheck2} label="Loan Applications" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/loanprogram" icon={FilePlus} label="Loan Program" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/f-programs" icon={FileText} label="Search Financing Programs" isCollapsed={isCollapsed} />
            {/* <SidebarNavLink to="/loan-history" icon={FileText} label="Loan History" isCollapsed={isCollapsed} /> */}
            <SidebarNavLink to="/employees" icon={Users} label="Employees" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/clients" icon={User} label="Clients" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/logs" icon={FileText} label="Logs" isCollapsed={isCollapsed} />
            <SidebarNavLink to="/broker-company" icon={Building} label="Broker Company" isCollapsed={isCollapsed} />

            {/* Conditional Rendering */}
          
              <SidebarNavLink to="/role" icon={UserCheck} label="Role" isCollapsed={isCollapsed} />
            
        
              <SidebarNavLink to="/permission" icon={Lock} label="Permissions" isCollapsed={isCollapsed} />
            
          </nav>
        </div>

        {/* Collapse Button */}
        <button
          onClick={handleToggleCollapse}
          className={`absolute bottom-4 ${isRTL ? 'left-[-10px]' : 'right-[-10px]'} text-white dark:text-gray-300 bg-secondaryColor rounded-full shadow-lg`}
        >
          {isCollapsed ? <RiArrowRightSLine size={24} /> : <RiArrowLeftSLine size={24} />}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
