import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { toast } from "react-toastify";

const SingleFProgramsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { programs, loanApplicationData } = location.state || {};
  const [uploadedDocuments, setUploadedDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const [programDocuments, setProgramDocuments] = useState({});
  const [bankEmployees, setBankEmployees] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
 

  console.log("program", programs);
  console.log("loanApplicationData",loanApplicationData)
  console.log("-----",loanApplicationData?.eligibilityStatus)

  // Collect all unique required documents across all programs
  const allRequiredDocuments = new Set();
  programs?.forEach((program) => {
    program.baseFields.requiredDocuments?.forEach((document) => {
      allRequiredDocuments.add(document);
    });
  });

  useEffect(() => {
    // Fetch employees for the initially selected banks
    programs.forEach((program, index) => {
      if (program.baseFields.selectedBank) {
        fetchBankEmployees(program.baseFields.selectedBank, index);
      }
    });
  }, [programs]);

  const handleFileUpload = (documentName, event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedDocuments((prev) => ({
        ...prev,
        [documentName]: file,
      }));
      const updatedProgramDocuments = { ...programDocuments };
      programs.forEach((program) => {
        if (program.baseFields.requiredDocuments.includes(documentName)) {
          updatedProgramDocuments[program.baseFields.programName] = {
            ...(updatedProgramDocuments[program.baseFields.programName] || {}),
            [documentName]: file,
          };
        }
      });
      setProgramDocuments(updatedProgramDocuments);
    }
  };

  const fetchBankEmployees = async (bankId, programIndex) => {
    try {
      setLoadingEmployees(true);
      const bankEmployeeCollectionRef = collection(db, "Banks", bankId, "BankEmployee");
      const employeeSnapshot = await getDocs(bankEmployeeCollectionRef);
      const employees = employeeSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBankEmployees((prev) => ({
        ...prev,
        [programIndex]: employees,
      }));
    } catch (error) {
      console.error("Error fetching bank employees:", error);
    } finally {
      setLoadingEmployees(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const storage = getStorage();
      const applicationId = `CBF-AP-${(Math.random() * 10000).toFixed(0)}`;
      const userId = loanApplicationData?.userId;
      const userName=loanApplicationData?.userName;
  
      if (!userId) {
        throw new Error("User ID is missing.");
      }
  
      const totalLoanAmount = programs.reduce(
        (total, program) => total + parseFloat(loanApplicationData?.loanAmount || 0),
        0
      );
      const applicationDate = new Date().toISOString();
      const status = "In Progress";
  
      // Create an array for the bankApplications
      const bankApplications = await Promise.all(
        programs.map(async (program, index) => {
          const bankApplicationId = `bank_app_${Date.now()}_${index}`;
          const bankName = program.baseFields.selectedBank;
          const loanAmount = loanApplicationData?.loanAmount || 0;
          const eligibilityStatus = loanApplicationData?.eligibilityStatus;
          const approvalStatus = loanApplicationData?.approvalStatus;
          const approvalDate = eligibilityStatus ? null : new Date().toISOString();
          const interestRate = program?.baseFields?.loanDetails?.interestRate || null;
          const creditLimit = program?.baseFields?.eligibilityCriteria?.creditScore || null;
          const loanDocuments = [];
  
          if (program.baseFields.requiredDocuments) {
            for (const docName of program.baseFields.requiredDocuments) {
              const file = programDocuments[program.baseFields.programName]?.[docName];
              if (file) {
                const storageRef = ref(
                  storage,
                  `loanApplications/${applicationId}/documents/${docName}`
                );
                const snapshot = await uploadBytes(storageRef, file);
                const url = await getDownloadURL(snapshot.ref);
  
                loanDocuments.push({
                  documentName: docName,
                  status: "Submitted",
                  url, // Public URL from Firebase Storage
                  fileName: file.name,
                });
              }
            }
          }
  
          const history = [
            {
              timestamp: new Date().toISOString(),
              action: "Submitted Application",
              status: approvalStatus,
              comments: eligibilityStatus
                ? "Application submitted successfully."
                : "User did not meet eligibility criteria.",
            },
          ];
  
          const assignedBankEmployee = bankEmployees[index]?.[0] || null;
  
          return {
            bankApplicationId,
            bank: bankName,
            loanAmount,
            eligibilityStatus,
            approvalStatus,
            approvalDate,
            interestRate,
            creditLimit,
            loanDocuments,
            history,
            assignedBankEmployee,
          };
        })
      );
  
      const loanApplication = {
        applicationId,
        userId,
        userName,
        loanProgram: "Personal Loan",
        totalLoanAmount,
        applicationDate,
        status,
        bankApplications,
      };
  
      // Validate required fields
      if (
        !loanApplication.applicationId ||
        !loanApplication.userId ||
        !loanApplication.applicationDate ||
        !Array.isArray(loanApplication.bankApplications)
      ) {
        throw new Error("Missing required fields in loan application.");
      }
  
      // Save the loan application to Firestore
      await setDoc(doc(db, "LoanApplication", applicationId), loanApplication);
  
      console.log("Loan application submitted successfully.");
      toast.success("Loan application submitted successfully!");
      navigate("/loan-application"); 
    } catch (error) {
      console.error("Error submitting loan application:", error.message, error);
    }
    finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="rounded-lg shadow-lg p-8">
    
      {programs ? (
        <div>
          <h1 className="text-2xl">Required Documents</h1>
          {allRequiredDocuments.size > 0 ? (
            <div className="flex flex-wrap -mx-2">
            {[...allRequiredDocuments].map((document, index) => (
             <div key={index} className="w-full md:w-1/2 px-2 mb-4">
             <label className="block font-medium mb-2">{document}:</label>
             <input
               type="file"
               accept="application/pdf,image/*"
               onChange={(e) => handleFileUpload(document, e)}
               className="border p-1 rounded-lg w-full file:bg-gradient-to-r file:from-secondaryColor file:to-primaryColor file:text-white file:cursor-pointer file:border-none file:rounded-sm"
             />
             {uploadedDocuments[document] && (
               <p className="text-sm text-gray-600 mt-2">
                 Selected file: {uploadedDocuments[document].name}
               </p>
             )}
           </div>
           
            ))}
          </div>
          
          ) : (
            <p>No required documents found for this program.</p>
          )}
        </div>
      ) : (
        <p>No programs selected.</p>
      )}

      <h1 className="text-2xl">Program Details</h1>
      {programs.map((program, programIndex) => (
        <div key={programIndex} className="border-b border-gray-200 pb-4 mb-4">
          <h3 className="font-bold text-secondaryColor mb-2">
            Program: {program.baseFields.programName}
          </h3>

          {/* Display selected bank */}
          <p>Bank: {program.baseFields.selectedBank}</p>

          {/* Display employees based on the selected bank */}
          <div className="mb-4">
            <label htmlFor={`employee-${programIndex}`} className="block text-sm font-medium text-gray-700">
              Select Employee:
            </label>
            <select
              id={`employee-${programIndex}`}
              className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-secondaryColor focus:border-secondaryColor sm:text-sm rounded-md"
              disabled={loadingEmployees}
            >
              <option value="">-- Select an Employee --</option>
              {bankEmployees[programIndex] && bankEmployees[programIndex].length > 0 ? (
                bankEmployees[programIndex].map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.bankEmployeeName}
                  </option>
                ))
              ) : (
                <option disabled>No employees available</option>
              )}
            </select>
          </div>

          {/* Uploaded Documents */}
          {program.baseFields.requiredDocuments && (
            <div>
              <h4 className="font-semibold text-gray-700">Uploaded Documents:</h4>
              {program.baseFields.requiredDocuments.map((document, index) => (
                <div key={index} className="mb-2">
                  {programDocuments[program.baseFields.programName] &&
                  programDocuments[program.baseFields.programName][document] ? (
                    <p className="text-sm text-gray-600">
                      {document}:{" "}
                      {
                        programDocuments[program.baseFields.programName][document]
                          .name
                      }
                    </p>
                  ) : (
                    <p className="text-sm text-gray-600">
                      No document uploaded for {document}.
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {/* Submit Button */}
      <div className="mt-4">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-gradient-to-r from-secondaryColor to-primaryColor  text-white font-bold rounded-md hover:bg-primaryColorDark focus:outline-none focus:ring-2 focus:ring-primaryColor focus:ring-opacity-50"
        >
            {loading ? "Loading..." : " Submit Loan Application"}
         
        </button>
      </div>
    </div>
  );
};

export default SingleFProgramsPage;
