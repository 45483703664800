import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Firestore instance
import { toast } from "react-toastify";

const ViewSingleLog = () => {
  const { id } = useParams(); // Get the ID from the route
  const [logDetails, setLogDetails] = useState(null); // State to hold log details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  console.log("Iddddd",id)

  useEffect(() => {
    const fetchLogDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch the document from Firestore
        const logDoc = await getDoc(doc(db, "GlobalActivityLogs", id));
        if (logDoc.exists()) {
          setLogDetails(logDoc.data());
        } else {
          setError("Log not found.");
          toast.error("Log not found.");
        }
      } catch (err) {
        console.error("Error fetching log details:", err);
        setError("Failed to fetch log details.");
        toast.error("Failed to fetch log details.");
      } finally {
        setLoading(false);
      }
    };

    fetchLogDetails();
  }, [id]);

  // Render loading, error, or log details
  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6 bg-white shadow rounded">
      <h1 className="font-bold text-xl mb-4">Log Details</h1>

      {logDetails ? (
        <div>
          <p><strong>Timestamp:</strong> {new Date(logDetails.timestamp).toLocaleString()}</p>
          <p><strong>User Name:</strong> {logDetails.username}</p>
          <p><strong>Action Message:</strong> {logDetails?.action?.message}</p>
          <p><strong>Action Type:</strong> {logDetails?.action?.type}</p>
          <p><strong>Os:</strong> {logDetails.os || "N/A"}</p>
          <p><strong>Device:</strong> {logDetails.deviceType || "N/A"}</p>
        </div>
      ) : (
        <p className="text-gray-500">No log details found.</p>
      )}
    </div>
  );
};

export default ViewSingleLog;
