import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetchActivitiesForUser,
  fetchAllUsers,
} from "../../services/loggingService";
import { toast } from "react-toastify";
import { Eye } from "lucide-react";
import { Link } from "react-router-dom";

const Loggs = () => {
  const [allActivities, setAllActivities] = useState([]); // All fetched activities
  const [filteredActivities, setFilteredActivities] = useState([]); // Activities filtered by date
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [searchDate, setSearchDate] = useState(""); // Search date for filtering activities

  useEffect(() => {
    // Function to fetch users and their activities
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch all users first
        const fetchedUsers = await fetchAllUsers();

        console.log("Fetch all users", fetchedUsers);

        // Initialize a list to store all activities
        const allActivitiesList = [];

        // Fetch activities for each user
        for (const user of fetchedUsers) {
          const activities = await fetchActivitiesForUser(user.id);

          // Map activities to include user details and a unique ID for each activity
          const userActivities = activities.map((activity) => ({
            ...activity,
            userId: user.id,
            message: activity?.action?.message,
            type: activity?.action?.type,

            // id: `${user.id}-${activity.timestamp}`, 
          }));

          allActivitiesList.push(...userActivities); // Add user's activities to the list
        }

        // Once all activities are fetched, set the state
        setAllActivities(allActivitiesList);
        setFilteredActivities(allActivitiesList); // Initialize filtered data

        console.log("allActivitiesList", allActivitiesList);
      } catch (error) {
        console.error("Error fetching users or activities: ", error);
        setError("Failed to load activities.");
        toast.error("Failed to load activities.");
      } finally {
        setLoading(false);
      }
    };

    fetchAllData(); // Trigger data fetching when the component mounts
  }, []);

  // Handle search by date
  const handleSearch = (event) => {
    const inputDate = event.target.value;
    setSearchDate(inputDate);

    if (inputDate) {
      // Filter activities by selected date
      const filtered = allActivities.filter(
        (activity) =>
          new Date(activity.timestamp).toLocaleDateString() ===
          new Date(inputDate).toLocaleDateString()
      );
      setFilteredActivities(filtered);
    } else {
      setFilteredActivities(allActivities); // Reset to all activities if no date is selected
    }
  };

  const columns = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "message",
      headerName: "Action Message",
      flex: 2,
      headerClassName: "header",
    },
    {
      field: "type",
      headerName: "Action Type",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {/* Edit Icon - Render based on permission */}

          <Link
            to={`/view-logs/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <Eye className="text-white cursor-pointer h-4 w-4" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between">
        <div>
          <h1 className="font-bold text-xl mb-4 text-secondaryColor">All User Activities</h1>
        </div>
        <div className="mb-4">
          <label htmlFor="search-date" className="mr-2">
            Search by Date:
          </label>
          <input
            type="date"
            id="search-date"
            value={searchDate}
            onChange={handleSearch}
            className="p-2 border rounded"
          />
        </div>
      </div>

      {/* Show loading or error messages */}
      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : filteredActivities.length === 0 ? (
        <div className="flex justify-center items-center h-[80vh]">
          <p className="text-gray-500">No activities found.</p>
        </div>
      ) : (
        // Display the DataGrid once the activities are fetched
        <DataGrid
          rows={filteredActivities}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default Loggs;
