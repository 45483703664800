import React, { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrash, FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useSelector } from "react-redux";

const LoanApplicationTable = () => {
  const [loanApplications, setLoanApplications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanApplications = async () => {
      try {
        const appliedLoanRef = collection(db, "LoanApplication");
        const querySnapshot = await getDocs(appliedLoanRef);
        const loanData = querySnapshot.docs.map((doc, index) => ({
          serialNo: index + 1, // Dynamic Serial No
          id: doc.id, // Document ID
          ...doc.data(), // Document data
        }));
        setLoanApplications(loanData);
        console.log("Loan Application",loanApplications)
      } catch (error) {
        setError("Error fetching loan applications. Please try again later.");
        console.error("Error fetching loan applications:", error);
      } finally {
        setLoading(false); // Ensure loading is stopped
      }
    };

    fetchLoanApplications();
  }, []);

  const { permissions } = useSelector((state) => state.permissions);

  const permissionObject = Array.isArray(permissions)
    ? permissions.find((perm) => typeof perm === "object") || {}
    : permissions; // Assuming permissions might already be an object

  const canAdd = permissionObject.ApplicationAdd || false;
  const canEdit = permissionObject.ApplicationUpdate || false;
  const canDelete = permissionObject.ApplicationDelete || false;
  const canView = permissionObject.ApplicationView || false;

  const handleDelete = async (id) => {
    if (!canDelete) {
      toast.error("You don't have permission to delete.");
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this data?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "LoanApplication", id));
        setLoanApplications(loanApplications.filter((item) => item.id !== id));
        toast.success("Loan History deleted successfully!");
        logUserActivity("User Deleted Applied Loan Application");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data.");
        toast.error("Failed to delete data.");
      }
    }
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "id",
      headerName: "Loan Id",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      headerClassName: "header",
    },   
    {
      field: "loanProgram",
      headerName: "Loan Program",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
   
    
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {/* View Icon */}
          {canView && (
            <Link
              to={`/view-loan/${params.row.id}`}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaEye className="text-white cursor-pointer h-4 w-4" />
            </Link>
          )}

          {/* Edit Icon */}
          {canEdit && (
            <Link
              to={`/update-services/${params.row.id}`}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
            </Link>
          )}

          {/* Delete Icon */}
          {canDelete && (
            <div
              onClick={() => handleDelete(params.row.id)}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">All Loan Applications</h1>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : loanApplications.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No data found</p>
        </div>
      ) : (
        <DataGrid
          rows={loanApplications}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default LoanApplicationTable;
