import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewLoanSchemeForm = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.permissions);

  const permissionObject = Array.isArray(permissions)
    ? permissions.find((perm) => typeof perm === "object") || {}
    : permissions;

  const canAdd = permissionObject.LoanProgramAdd || false;
  const canEdit = permissionObject.LoanProgramUpdate || false;
  const canDelete = permissionObject.LoanProgramDelete || false;

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "programName", // Display the program name
      headerName: "Program Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "selectedProgramType", // Display the program name
      headerName: "Program Types",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "selectedBank", // Display the selected bank
      headerName: "Bank",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "minSalary", // Display the minimum salary
      headerName: "Min Salary",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "maxLoanAmount", // Display the maximum loan amount
      headerName: "Max Loan Amount",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          <Link
            to={`/view-loanprogram/${params.row.id}`}
            className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
          >
            <FaEye className="text-white cursor-pointer h-4 w-4" />
          </Link>
          {canEdit && (
            <Link
              to={`/update-services/${params.row.id}`}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
            </Link>
          )}
          {canDelete && (
            <div
              onClick={() => handleDelete(params.row.id)}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "LoanProgram"));
        const products = querySnapshot.docs.map((doc, index) => {
          const data = doc.data();
          return {
            id: doc.id,
            serialNo: index + 1,
            programName: data.baseFields.programName,
            selectedProgramType: data.baseFields.selectedProgramType,
            selectedBank: data.baseFields.selectedBank,
            minSalary: data.baseFields.eligibilityCriteria?.minSalary || "N/A",
            maxLoanAmount: data.baseFields.loanDetails?.maxLoanAmount || "N/A",
          };
        });
        console.log("Fetched Data: ", products); // Log the fetched data
        setData(products);
        logUserActivity("User Viewed Loan Program");
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this data?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "LoanProgram", id));
        setData(data.filter((item) => item.id !== id));
        logUserActivity("User Deleted Loan Program");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl text-secondaryColor">
            All Loan Programs
          </h1>
        </div>

        <div>
          {canAdd && (
            <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
              <Link to="/add-loanprogram">Add Loan Program</Link>
            </button>
          )}
        </div>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No data found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default ViewLoanSchemeForm;
