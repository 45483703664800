import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection, doc, updateDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/fields/TextInput";
import { logUserActivity } from "../../services/logUserActivity";

const bankSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Bank Name is required" })
    .max(30, { message: "Max 30 characters" }),
  headquarters: z
    .string()
    .min(1, { message: "Location is required" })
    .max(300, { message: "Max 300 characters" }),
  contactNumber: z
    .string()
    .min(1, { message: "Contact Number is required" })
    .max(15, { message: "Max 15 characters" }),
});

const AddBank = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: zodResolver(bankSchema) });
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      // Add bank document to Banks collection
      const bankRef = await addDoc(collection(db, "Banks"), {
        name: data.name,
        headquarters: data.headquarters,
        contactNumber: data.contactNumber,
      });

      // Retrieve bankId from newly added bank
      const bankId = bankRef.id;

      // Check if LoanStatistics document exists
      const loanStatsRef = doc(db, "LoanStatistics", "AABCNV6rull84tAwtRS6"); // Replace "statsDocumentId" with the actual document ID for LoanStatistics
      const loanStatsSnapshot = await getDoc(loanStatsRef);

      if (loanStatsSnapshot.exists()) {
        // If the document already exists, update it with the new bank statistics
        await updateDoc(loanStatsRef, {
          bankStatistics: {
            ...loanStatsSnapshot.data().bankStatistics,
            [bankId]: {
              totalApplications: 0,
              approvedLoans: 0,
              totalLoanAmount: 0,
              approved: 0,
              inProgress: 0,
              pending: 0,
              rejected: 0,
              requireMoreDocuments: 0,
            },
          },
        });
        toast.success("Bank added successfully, and LoanStatistics updated!");
      } else {
        toast.warn("LoanStatistics document does not exist. Cannot update bank statistics.");
      }

      logUserActivity("User Added Bank");
      reset();
      navigate("/banks");
    } catch (error) {
      console.error("Error adding document:", error);
      toast.error("Failed to add bank.");
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create Bank</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-between gap-6">
              <div className="w-full">
                <InputField
                  label="Bank Name"
                  error={errors.name?.message}
                  register={register}
                  name="name"
                />
              </div>

              <div className="w-full">
                <InputField
                  label="Headquarters Location"
                  error={errors.headquarters?.message}
                  register={register}
                  name="headquarters"
                />
              </div>
            </div>
            <InputField
              label="Contact Number"
              error={errors.contactNumber?.message}
              register={register}
              name="contactNumber"
            />

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Bank
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBank;
