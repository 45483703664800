import React from "react";
import Select from "react-select";

const SearchableDropdown = ({
  options,
  selectedValue,
  setSelectedValue,
  placeholder = "Select an option",
  formFieldName,
  setValue,
}) => {
  const handleChange = (selectedOption) => {
    const value = selectedOption?.value || "";
    setSelectedValue(value); // Update local state
    if (setValue && formFieldName) {
      setValue(formFieldName, value); // Update form value if needed
    }
  };

  // Ensure options are in the correct format for react-select
  const formattedOptions = options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  return (
    <div className="w-full">
      <Select
        options={formattedOptions} // Dropdown options
        value={formattedOptions.find((option) => option.value === selectedValue)} // Selected option
        onChange={handleChange} // Handle selection changes
        placeholder={placeholder} // Placeholder text
        isClearable // Allow clearing the selection
        className="w-full"
        styles={{
          control: (base) => ({
            ...base,
            borderColor: "gray",
            borderRadius: "8px",
            padding: "2px",
          }),
          menu: (base) => ({
            ...base,
            zIndex: 10, // Ensure dropdown appears above other elements
          }),
        }}
      />
    </div>
  );
};

export default SearchableDropdown;
