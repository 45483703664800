import React, { useEffect, useState } from 'react';

import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { Link } from 'react-router-dom';
import { FaEye, FaTrash } from 'react-icons/fa';
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { logUserActivity } from '../../services/logUserActivity';

const LoanHistory = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanApplications = async () => {
      try {
        const appliedLoanRef = collection(db, "LoanHistories");
        const querySnapshot = await getDocs(appliedLoanRef);
        const loanData = querySnapshot.docs.flatMap((doc, index) => {
          const loanId = doc.id; // Unique loan ID
          const loanHistory = doc.data().loanHistory || [];
        
          return loanHistory.map((entry, historyIndex) => ({
            id: `${loanId}-${historyIndex}`, // Unique ID for each row
            serialNo: `${index + 1}.${historyIndex + 1}`,
            loanId,
            ...entry, // Include action, status, comments, timestamp
          }));
        });
        

        setData(loanData);
        logUserActivity("User Viewed Loan History");
        console.log("loanData", loanData);
      } catch (error) {
        setError("Error fetching loan applications. Please try again later.");
        console.error("Error fetching loan applications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoanApplications();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this loan history?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "LoanHistories", id));
        setData(data.filter((item) => item.id !== id));
        toast.success("Loan History deleted successfully!");
        logUserActivity("User Deleted Loan History");
      } catch (error) {
        console.error("Error deleting  data: ", error);
        setError("Failed to delete  data.");
        toast.error("Failed to delete  data.");
      }
    }
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "loanId",
      headerName: "Loan ID",
      flex: 1,
      headerClassName: "header",
    },
  
    {
      field: "document_action",
      headerName: "Document Action",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 2,
      headerClassName: "header",
    },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
        headerClassName: "header",
        renderCell: (params) => (
          <div className="flex items-center gap-2 h-full">
           
            <Link
              to={`/view-loan-history/${params.row.loanId}`}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaEye className="text-white cursor-pointer h-4 w-4" />
            </Link>
  
            {/* Delete Icon */}
            <div
               onClick={() => handleDelete(params.row.id)} 
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          </div>
        ),
      },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">All Loan Histories</h1>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No data found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default LoanHistory;
