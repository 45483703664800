import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { loanSchema } from "../../models/loanProgramSchema";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/fields/TextInput";
import Checkbox from "../../components/CheckBox";
import MultiSelect from "../../components/MultiSelect";
import { logUserActivity } from "../../services/logUserActivity";
import SearchableDropdown from "../../components/fields/SearchableDropdown";

const AddLoanSchemeForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: zodResolver(loanSchema),
    defaultValues: {
      baseFields: {
        selectedBank: "",
        programName: "",
        selectedProgramType: "",
        eligibilityCriteria: {
          minSalary: "",
          maxDBR: "",
          creditScore: "",
          minAge: "",
          maxAge: "",
        },
        loanDetails: {
          minLoanAmount: "",
          maxLoanAmount: "",
          interestRate: "",
          tenure: [],
        },
        requiredDocuments: [],
      },
      programSpecificFields: {
        prePaymentPenalty: false,
        insuranceRequired: false,
      },
    },
  });

  const navigate = useNavigate();

  const [banks, setBanks] = useState([]);
  const [programTypes, setProgramType] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedProgramType, setSelectedProgramType] = useState("");

  // Tenure Options
  const tenureOptions = [
    { id: 6, name: "6 Months" },
    { id: 12, name: "12 Months" },
    { id: 18, name: "18 Months" },
    { id: 24, name: "24 Months" },
    { id: 36, name: "36 Months" },
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [newDocument, setNewDocument] = useState("");

  const [requiredDocs, setRequiredDocs] = useState([
    { id: "passport", name: "Passport" },
    { id: "id_card", name: "ID Card" },
    { id: "utility_bill", name: "Utility Bill" },
    { id: "bank_statement", name: "Bank Statement" },
  ]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankSnapshot = await getDocs(banksCollectionRef);
        const banksData = bankSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    const fetchProgramType = async () => {
      try {
        const programTypesCollectionRef = collection(db, "ProgramType");
        const programTypeSnapshot = await getDocs(programTypesCollectionRef);
        const programTypesData = programTypeSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProgramType(programTypesData);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };

    fetchProgramType();
  }, []);

  useEffect(() => {
    setValue("baseFields.selectedBank", selectedBank);
  }, [selectedBank, setValue]);

  useEffect(() => {
    setValue("baseFields.selectedProgramType", selectedProgramType); // Set program type in form state
  }, [selectedProgramType, setValue]);

  const onSubmit = async (data) => {
    console.log("jkjk", data);
    try {
      await addDoc(collection(db, "LoanProgram"), data);
      toast.success("Successfully added loan program!");
      logUserActivity("User Added Loan Program");
      navigate("/loanprogram");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Failed to submit loan program.");
    }
  };

  const handleAddDocument = () => {
    if (newDocument.trim()) {
      const newDoc = { id: newDocument.toLowerCase(), name: newDocument };
      setRequiredDocs((prevDocs) => [...prevDocs, newDoc]);
      setNewDocument("");
      setShowPopup(false);
      toast.success("Document added successfully!");
    } else {
      toast.error("Please enter a valid document name.");
    }
  };

  const bankOptions = banks.map((bank) => ({
    value: bank.id,
    label: bank.name,
  }));

  const programOptions = programTypes.map((program) => ({
    value: program.id,
    label: program.programtype,
  }));


  return (
    <div>
      <h1 className="font-bold text-xl">Create Loan Program</h1>
      <div className="rounded-lg shadow-lg">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[90%] mx-auto flex flex-col gap-4 p-10"
        >
          <div className="w-full">
            <InputField
              label="Program Name"
              name="baseFields.programName"
              register={register}
              error={errors.baseFields?.programName?.message}
            />
          </div>

          <div className="flex justify-between gap-6">
          
            <div className="w-full">
              <Controller
                name="programtype"
                control={control}
                render={({ field }) => (
                  <SearchableDropdown
                    options={programOptions}
                    selectedValue={selectedProgramType}
                    setSelectedValue={(value) => {
                      setSelectedProgramType(value);
                      field.onChange(value); // Ensure the value is passed to react-hook-form
                    }}
                    placeholder="Select a programType"
                  />
                )}
              />
            </div>

            <div className="w-full">
              <Controller
                name="bank"
                control={control}
                render={({ field }) => (
                  <SearchableDropdown
                    options={bankOptions}
                    selectedValue={selectedBank}
                    setSelectedValue={(value) => {
                      setSelectedBank(value);
                      field.onChange(value); // Ensure the value is passed to react-hook-form
                    }}
                    placeholder="Select a Bank"
                  />
                )}
              />
            </div>

          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <InputField
                label="Minimum Salary"
                name="baseFields.eligibilityCriteria.minSalary"
                type="number"
                placeholder="e.g., 2000 USD/month"
                register={register}
                error={
                  errors.baseFields?.eligibilityCriteria?.minSalary?.message
                }
              />
            </div>

            <div className="w-full">
              <InputField
                label="Credit Score"
                name="baseFields.eligibilityCriteria.creditScore"
                type="number"
                placeholder="example(200-300)"
                register={register}
                error={
                  errors.baseFields?.eligibilityCriteria?.creditScore?.message
                }
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <InputField
                label="Maximum DBR (%)"
                name="baseFields.eligibilityCriteria.maxDBR"
                type="number"
                placeholder="e.g., 50"
                register={register}
                error={errors.baseFields?.eligibilityCriteria?.maxDBR?.message}
              />
            </div>

            <div className="w-full">
              <InputField
                label="Interest Rate (%)"
                name="baseFields.loanDetails.interestRate"
                type="number"
                placeholder="e.g., 3.5 (as a percentage)"
                register={register}
                error={errors.baseFields?.loanDetails?.interestRate?.message}
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <InputField
                label="Min Age"
                name="baseFields.eligibilityCriteria.minAge"
                type="number"
                placeholder="e.g., 18 (minimum age)"
                register={register}
                error={errors.baseFields?.eligibilityCriteria?.minAge?.message}
              />
            </div>

            <div className="w-full">
              <InputField
                label="Max Age"
                name="baseFields.eligibilityCriteria.maxAge"
                type="number"
                placeholder="e.g., 65 (maximum age)"
                register={register}
                error={errors.baseFields?.eligibilityCriteria?.maxAge?.message}
              />
            </div>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <InputField
                label="Minimum Loan Amount"
                name="baseFields.loanDetails.minLoanAmount"
                type="number"
                placeholder="e.g., 5,000"
                register={register}
                error={errors.baseFields?.loanDetails?.minLoanAmount?.message}
              />
            </div>
            <div className="w-full">
              <InputField
                label="Maximum Loan Amount"
                name="baseFields.loanDetails.maxLoanAmount"
                type="number"
                placeholder="e.g., 50,000"
                register={register}
                error={errors.baseFields?.loanDetails?.maxLoanAmount?.message}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <label className="font-medium text-sm text-gray-700">
              Tenure (Months)
            </label>
            <div className="grid grid-cols-3 gap-4">
              {tenureOptions.map((option, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id={`tenure-${option.id}`}
                    value={option.id}
                    {...register("baseFields.loanDetails.tenure")}
                  />
                  <label
                    htmlFor={`tenure-${option.id}`}
                    className="text-sm text-gray-700"
                  >
                    {option.name}
                  </label>
                </div>
              ))}
            </div>
            {errors.baseFields?.loanDetails?.tenure?.message && (
              <p className="text-sm text-red-500">
                {errors.baseFields?.loanDetails?.tenure?.message}
              </p>
            )}
          </div>

          <div className="w-full">
            <label>Required Documents</label>
            {requiredDocs.map((doc) => (
              <div key={doc.id} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id={doc.id}
                  value={doc.id}
                  {...register("baseFields.requiredDocuments", {
                    required: "You must select at least one document.",
                  })}
                />
                <label htmlFor={doc.id}>{doc.name}</label>
              </div>
            ))}

            <button
              type="button"
              onClick={() => setShowPopup(true)}
              className="mt-2 text-blue-500 underline"
            >
              Add New Document
            </button>
          </div>

          <div className="flex justify-between gap-6">
            <div className="w-full">
              <Checkbox
                label="Pre-Payment Penalty"
                name="programSpecificFields.prePaymentPenalty"
                register={register}
              />
            </div>

            <div className="w-full">
              <Checkbox
                label="Insurance Required"
                name="programSpecificFields.insuranceRequired"
                register={register}
              />
            </div>
          </div>

          <button
            type="submit"
            className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
          >
            Submit Loan Program
          </button>
        </form>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">Add New Document</h2>
            <InputField
              label="Document Name"
              value={newDocument}
              onChange={(e) => setNewDocument(e.target.value)}
              placeholder="Enter document name"
            />
            <div className="mt-4 flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setShowPopup(false)}
                className="px-4 py-2 bg-gray-300 rounded-lg"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleAddDocument}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLoanSchemeForm;
