import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash } from "react-icons/fa";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BankEmployee = () => {
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const { permissions } = useSelector((state) => state.permissions);

  // Check permissions
  const permissionObject = Array.isArray(permissions)
    ? permissions.find((perm) => typeof perm === "object") || {}
    : permissions;

  const canDelete = permissionObject.DepartmentDelete || false;

  useEffect(() => {
    dispatch(fetchPermissions());
    fetchBankEmployees();
  }, [dispatch]);

  // Fetch bank employees from Firestore
  const fetchBankEmployees = async () => {
    try {
      setLoading(true);
      const banksCollectionRef = collection(db, "Banks");
      const banksSnapshot = await getDocs(banksCollectionRef);

      const employeePromises = banksSnapshot.docs.map(async (bankDoc) => {
        const bankId = bankDoc.id;
        const bankEmployeeCollectionRef = collection(
          db,
          "Banks",
          bankId,
          "BankEmployee"
        );
        const employeeSnapshot = await getDocs(bankEmployeeCollectionRef);
        return employeeSnapshot.docs.map((employeeDoc) => ({
          id: employeeDoc.id,
          bankId,
          ...employeeDoc.data(),
        }));
      });

      const allEmployees = (await Promise.all(employeePromises)).flat();
      setEmployees(allEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Failed to fetch employees.");
    } finally {
      setLoading(false);
    }
  };

  // Delete employee document
  const handleDelete = async (employeeId, bankId) => {
    try {
      await deleteDoc(doc(db, "Banks", bankId, "BankEmployee", employeeId));
      toast.success("Employee deleted successfully!");
      logUserActivity("User deleted a bank employee");
      fetchBankEmployees(); // Refresh the list
    } catch (error) {
      console.error("Error deleting employee:", error);
      toast.error("Failed to delete employee.");
    }
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      headerClassName: "header",
      flex: 0.5,
    },
    {
        field: "employeeId",
        headerName: "Employee ID",
        headerClassName: "header",
        flex: 1,
      },
    {
      field: "bankEmployeeName",
      headerName: "Employee Name",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "employeeDepartment",
      headerName: "Department",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "contactNumber",
      headerName: "Contact",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "bankEmployeeEmail",
      headerName: "Email",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => {
        // Check if the id exists before rendering the delete button
        const employeeId = params.row.id;
        if (!employeeId) {
          return <span>No ID available</span>; // Fallback if no ID exists
        }
        return (
          <div className="flex items-center gap-2 h-full">
            <div
              onClick={() => handleDelete(employeeId, params.row.bankId)} 
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          </div>
        );
      },
    }
    
  ];

  const rows = employees.map((employee, index) => ({
    ...employee,
    serialNo: index + 1,
  }));

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl text-secondaryColor">All Bank Employees</h1>
        </div>
        <div>

            <Link
              to="/add-bank-employee"
              className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer"
            >
              Add Bank Employee
            </Link>
         
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            sx={{
              "& .header": {
                backgroundColor: "#214783",
                color: "#FFFFFF",
                fontWeight: "bold",
                fontSize: "16px",
              },
              "& .MuiDataGrid-cell": {
                backgroundColor: "#f4f4f4",
                color: "#000",
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BankEmployee;
