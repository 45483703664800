import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

const ViewSingleLoanSchemeForm = () => {
  const { id } = useParams();
  const [loanScheme, setLoanScheme] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLoanScheme = async () => {
      try {
        const docRef = doc(db, "LoanProgram", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setLoanScheme(docSnap.data());
        } else {
          setError("No such document!");
        }
      } catch (err) {
        console.error("Error fetching document:", err);
        setError("Failed to fetch loan scheme data.");
      } finally {
        setLoading(false);
      }
    };

    fetchLoanScheme();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-4 bg-white rounded shadow">
      <h1 className="text-2xl font-bold mb-4">Loan Scheme Details</h1>
      {loanScheme ? (
        <div className="space-y-4">
          <div className="border border-gray-400 rounded-2xl p-6">
            <div className="flex justify-between">
              <div>
                <strong>Program Name:</strong>{" "}
                {loanScheme.baseFields.programName}
              </div>
              <div>
                <strong>Bank:</strong> {loanScheme.baseFields.selectedBank}
              </div>
              <div>
                <strong>Minimum Salary:</strong>{" "}
                {loanScheme.baseFields.eligibilityCriteria?.minSalary || "N/A"}
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div>
                <strong>Maximum DBR:</strong>{" "}
                {loanScheme.baseFields.eligibilityCriteria?.maxDBR || "N/A"}
              </div>
              <div>
                <strong>Credit Score:</strong>{" "}
                {loanScheme.baseFields.eligibilityCriteria?.creditScore ||
                  "N/A"}
              </div>
              <div>
                <strong>Age Limit:</strong>{" "}
                {loanScheme.baseFields.eligibilityCriteria?.ageLimit || "N/A"}
              </div>
            </div>
          </div>

          <div className="border border-gray-400 rounded-2xl p-6">
            <div className="flex justify-between">
              <div>
                <strong>Minimum Loan Amount:</strong>{" "}
                {loanScheme.baseFields.loanDetails?.minLoanAmount || "N/A"}
              </div>
              <div>
                <strong>Maximum Loan Amount:</strong>{" "}
                {loanScheme.baseFields.loanDetails?.maxLoanAmount || "N/A"}
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <div>
                <strong>Interest Rate:</strong>{" "}
                {loanScheme.baseFields.loanDetails?.interestRate || "N/A"}%
              </div>
              <div>
                <strong>Tenure:</strong>{" "}
                {loanScheme.baseFields.loanDetails?.tenure?.length > 0
                  ? loanScheme.baseFields.loanDetails.tenure.join(", ")
                  : "N/A"}
              </div>
            </div>
          </div>

          <div>
            <strong>Required Documents:</strong>{" "}
            {loanScheme.baseFields.requiredDocuments?.length > 0
              ? loanScheme.baseFields.requiredDocuments.join(", ")
              : "N/A"}
          </div>
          <div>
            <strong>Pre-Payment Penalty:</strong>{" "}
            {loanScheme.programSpecificFields?.prePaymentPenalty ? "Yes" : "No"}
          </div>
          <div>
            <strong>Insurance Required:</strong>{" "}
            {loanScheme.programSpecificFields?.insuranceRequired ? "Yes" : "No"}
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ViewSingleLoanSchemeForm;
