import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";

const Applications = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.permissions);

  const permissionObject = Array.isArray(permissions)
  ? permissions.find((perm) => typeof perm === "object") || {}
  : permissions; // Assuming permissions might already be an object

const canAdd = permissionObject.ApplicationAdd || false;
const canEdit = permissionObject.ApplicationUpdate || false;
const canDelete = permissionObject.ApplicationDelete || false;


  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Client Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "age",
      headerName: "Client Age",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "employmentStatus",
      headerName: "Employment Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "annualIncome",
      headerName: "Annual Income",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <div className="flex items-center gap-2 h-full">
          {/* Edit Icon - only show if user has edit permission */}
          {canEdit && (
            <Link to={`/update-services/${params.row.id}`} className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8">
              <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />
            </Link>
          )}

          {/* Delete Icon - only show if user has delete permission */}
          {canDelete && (
            <div
              onClick={() => handleDelete(params.row.id)}
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
            >
              <FaTrash className="text-white h-4 w-4" />
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Applications"));
        const banks = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setData(banks);
        logUserActivity("User Viewed Application");
      } catch (error) {
        console.error("Error fetching Data: ", error);
        setError("Failed to load Data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Client?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Applications", id));
        setData(data.filter((item) => item.id !== id));
        toast.success("Data deleted successfully!");
        logUserActivity("User Deleted Application");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data.");
        toast.error("Failed to delete data.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-bold text-xl">Applications</h1>
        </div>

        <div>
          {/* Add Application Button - only show if user has add permission */}
          {canAdd && (
            <Link
              to="/add-application"
              className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer"
            >
              Add Application
            </Link>
          )}
        </div>
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No applications found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default Applications;
