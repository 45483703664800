import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form"; // Import Controller
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "../../components/fields/SearchableDropdown"; // Import SearchableDropdown

// Define the schema for employee input validation
const bankEmployeeSchema = z.object({
  bankEmployeeName: z
    .string()
    .min(1, { message: "Bank Employee Name is required" })
    .max(30, { message: "Bank Employee Name cannot exceed 30 characters" }),
  employeeDepartment: z
    .string()
    .min(1, { message: "Employee Department is required" })
    .max(50, { message: "Employee Department cannot exceed 50 characters" }),
  contactNumber: z
    .string()
    .min(1, { message: "Contact Number is required" })
    .max(15, { message: "Contact Number cannot exceed 15 characters" }),
  bankEmployeeEmail: z.string().email("Invalid email format").optional(),
});

const AddBankEmployee = () => {
  const navigate = useNavigate();
  const [banks, setBanks] = useState([]); // Holds available banks
  const [selectedBank, setSelectedBank] = useState(""); // Holds selected bank ID

  const {
    register,
    handleSubmit,
    control, // Add control for Controller
    formState: { errors },
  } = useForm({
    resolver: zodResolver(bankEmployeeSchema),
  });

  // Fetch all banks when the component mounts
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const banksCollectionRef = collection(db, "Banks");
        const bankSnapshot = await getDocs(banksCollectionRef);
        const banksData = bankSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name, // Ensure you have a name field in the bank document
        }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
        toast.error("Failed to fetch banks.");
      }
    };

    fetchBanks();
  }, []);

  const onSubmit = async (data) => {
    if (!selectedBank) {
      toast.warn("Please select a bank to add the employee.");
      return;
    }

    const formattedData = {
      bankEmployeeName: data.bankEmployeeName,
      employeeDepartment: data.employeeDepartment,
      contactNumber: data.contactNumber,
      ...(data.bankEmployeeEmail && { bankEmployeeEmail: data.bankEmployeeEmail }),
    };

    try {
      const bankEmployeeCollectionRef = collection(
        db,
        "Banks",
        selectedBank,
        "BankEmployee"
      );

      // Fetch all existing bank employees for the selected bank
      const bankEmployeesSnapshot = await getDocs(bankEmployeeCollectionRef);
      const bankEmployeesData = bankEmployeesSnapshot.docs.map((doc) => doc.data());

      // Find the highest serial number from existing employee IDs
      const highestEmployeeId = bankEmployeesData.reduce((max, emp) => {
        const empSerialNo = parseInt(emp.employeeId.split('-')[1], 10);
        return empSerialNo > max ? empSerialNo : max;
      }, 0);

      // Generate the next serial number for the new employee
      const newEmployeeId = `EMP-${(highestEmployeeId + 1).toString().padStart(4, '0')}`;

      // Add the new employee with the generated employeeId
      const newEmployeeData = { ...formattedData, employeeId: newEmployeeId };
      await addDoc(bankEmployeeCollectionRef, newEmployeeData);

      toast.success("Successfully added bank employee!");
      logUserActivity("User Added Bank Employee");
      navigate("/bank-employee");
    } catch (error) {
      console.error("Error adding employee document:", error);
      toast.error("Failed to add bank employee.");
    }
  };

  // Format banks for SearchableDropdown
  const bankOptions = banks.map((bank) => ({
    value: bank.id,
    label: bank.name,
  }));

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Add Bank Employee</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <div className="flex justify-center gap-6">
              {/* Bank Employee Name */}
              <div className="w-full">
                <InputField
                  label="Bank Employee Name"
                  error={errors.bankEmployeeName?.message}
                  register={register}
                  name="bankEmployeeName"
                  placeholder="Enter Name"
                />
              </div>

              {/* Employee Department */}
              <div className="w-full">
                <InputField
                  label="Employee Department"
                  error={errors.employeeDepartment?.message}
                  register={register}
                  name="employeeDepartment"
                   placeholder="Enter Department"
                />
              </div>
            </div>

            <div className="flex justify-center gap-6">
              {/* Contact Number */}
              <div className="w-full">
                <InputField
                  label="Contact Number"
                  error={errors.contactNumber?.message}
                  register={register}
                  name="contactNumber"
                   placeholder="Ente Contact"
                />
              </div>

              {/* Bank Employee Email */}
              <div className="w-full">
                <InputField
                  label="Bank Employee Email"
                  error={errors.bankEmployeeEmail?.message}
                  register={register}
                  name="bankEmployeeEmail"
                   placeholder="Enter Email"
                />
              </div>
            </div>

            {/* Select Bank using SearchableDropdown */}
            <div className="w-full">
              <Controller
                name="bank"
                control={control}
                render={({ field }) => (
                  <SearchableDropdown
                    options={bankOptions}
                    selectedValue={selectedBank}
                    setSelectedValue={(value) => {
                      setSelectedBank(value);
                      field.onChange(value); // Ensure the value is passed to react-hook-form
                    }}
                    placeholder="Select a Bank"
                  />
                )}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add Bank Employee
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBankEmployee;
