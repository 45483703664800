import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Importing icons
import { updateDoc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import { toast } from "react-toastify";

const ViewSingleLoanApplication = () => {
  const { id } = useParams();
  const [loanApplication, setLoanApplication] = useState(null);
  const [expandedBankId, setExpandedBankId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [newComments, setNewComments] = useState("");

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        setLoading(true);
        const docRef = doc(db, "LoanApplication", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const loanData = docSnap.data();
          setLoanApplication(loanData);
          console.log("loanData", loanData);
        } else {
          setError("Loan application not found.");
        }
      } catch (error) {
        console.error("Error fetching loan application:", error);
        setError("Error fetching loan application.");
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchLoanData();
  }, [id]);

  const columnsdocuments = [
    {
      field: "documentName",
      headerName: "Document Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "url",
      headerName: "View Document",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) => (
        <a
          href={params.value}
          className="text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          View Document
        </a>
      ),
    },
  ];

  const toggleExpandBank = (bankId) => {
    setExpandedBankId(expandedBankId === bankId ? null : bankId);
  };

  const openModal = (bank) => {
    setSelectedBank(bank);
    setNewStatus(bank.status || "");
    setNewComments("");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBank(null);
    setNewStatus("");
    setNewComments("");
  };

  const handleUpdateHistory = async () => {
    if (selectedBank) {
      // Update the local state
      const updatedBankApplications = loanApplication.bankApplications.map(
        (bank) =>
          bank.bank === selectedBank.bank
            ? {
                ...bank,
                history: [
                  ...(bank.history || []),
                  {
                    timestamp: new Date().toISOString(),
                    status: newStatus,
                    comments: newComments,
                  },
                ],
              }
            : bank
      );
  
      setLoanApplication((prev) => ({
        ...prev,
        bankApplications: updatedBankApplications,
      }));
  
      // Get the document reference for updating the Firestore database
      const loanDocRef = doc(db, "LoanApplication", id);
  
      try {
        // Update Firestore with the new history
        await updateDoc(loanDocRef, {
          bankApplications: updatedBankApplications,
        });
  
        // Show success toast
        toast.success("History status updated successfully!");
        logUserActivity("User Updated the History Status");
  
        // Close the modal after updating
        closeModal();
      } catch (error) {
        console.error("Error updating history in Firestore:", error);
        setError("Error updating history.");
      }
    }
  };
  

  const columnsHistory = [
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerClassName: "header",
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-10 rounded-lg shadow-lg">
      <h1 className="text-2xl text-secondaryColor">Loan Application Details</h1>

      {loanApplication ? (
        <div className="mt-4 mb-2 shadow-custom p-4 rounded">
          <div className="flex justify-between">
            <p>
              <strong>Loan Program:</strong> {loanApplication.loanProgram}
            </p>
            <p>
              <strong>Total Loan Amount:</strong>{" "}
              {loanApplication.totalLoanAmount}
            </p>
          </div>
          <div className="flex justify-between">
            <p>
              <strong>UserName:</strong> {loanApplication.userName}
            </p>
            <p>
              <strong>Status:</strong> {loanApplication.status}
            </p>
          </div>
        </div>
      ) : (
        <p>No data available.</p>
      )}

      <h2 className="text-xl mt-4 text-secondaryColor">Bank Applications</h2>
      {loanApplication?.bankApplications?.map((bank, index) => (
        <div
          key={index}
          className="mt-4 p-4 border rounded shadow-md cursor-pointer"
          onClick={() => toggleExpandBank(bank.bank)}
        >
          <div className="flex justify-between items-center">
            <div>
              <p>
                <strong>Bank:</strong> {bank.bank}
              </p>
              <p>
                <strong>Loan Amount:</strong> {bank.loanAmount}
              </p>
              <p>
                <strong>Status:</strong> {bank.approvalStatus}
              </p>
            </div>
            {/* Dropdown icon */}
            <div>
              {expandedBankId === bank.bank ? (
                <FaChevronUp className="text-gray-600 cursor-pointer" />
              ) : (
                <FaChevronDown className="text-gray-600 cursor-pointer" />
              )}
            </div>
          </div>

          {expandedBankId === bank.bank && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-secondaryColor">
                Bank Details
              </h3>
             
              <p>
                <strong>Interest Rate:</strong> {bank.interestRate}%
              </p>
              
              <p>
                <strong>Approval Status:</strong> {bank.approvalStatus}
              </p>
              {bank.assignedBankEmployee && (
                <div>
                  <h4 className="text-md font-semibold mt-2 text-secondaryColor">
                    Assigned Employee
                  </h4>
                  <p>
                    <strong>Name:</strong>{" "}
                    {bank.assignedBankEmployee.bankEmployeeName}
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    {bank.assignedBankEmployee.bankEmployeeEmail}
                  </p>
                  <p>
                    <strong>Contact:</strong>{" "}
                    {bank.assignedBankEmployee.contactNumber}
                  </p>
                </div>
              )}

              {/* Display Documents Table */}
              {bank.loanDocuments && bank.loanDocuments.length > 0 ? (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold text-secondaryColor">
                    Documents
                  </h3>

                  <DataGrid
                    rows={bank.loanDocuments.map((doc, index) => ({
                      id: index, // Ensure a unique id for each row
                      documentName: doc.documentName,
                      status: doc.status,
                      url: doc.url,
                    }))}
                    columns={columnsdocuments}
                    sx={{
                      "& .header": {
                        backgroundColor: "#214783",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "16px",
                      },
                      "& .MuiDataGrid-cell": {
                        backgroundColor: "#f4f4f4",
                        color: "#000",
                      },
                    }}
                    autoHeight // Automatically adjusts the grid height
                  />
                </div>
              ) : (
                <p className="text-gray-500 mt-2">
                  No documents available for this bank application.
                </p>
              )}

              {bank.history && bank.history.length > 0 ? (
                <div className="mt-2">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-lg font-semibold mt-4 text-secondaryColor">
                        Application History
                      </h3>
                    </div>

                    <div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent collapse toggle
                          openModal(bank);
                        }}
                        className="ml-4  my-4 px-3 py-1 text-white bg-gradient-to-r from-secondaryColor to-primaryColor  rounded hover:bg-blue-600"
                      >
                        Update History
                      </button>
                    </div>
                  </div>
                  <DataGrid
                    rows={bank.history.map((doc, index) => ({
                      id: index, // Ensure a unique id for each row
                      status: doc.status,
                      comments: doc.comments,
                      date: doc.timestamp, // Pass the timestamp directly
                    }))}
                    columns={columnsHistory}
                    sx={{
                      "& .header": {
                        backgroundColor: "#214783",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: "16px",
                      },
                      "& .MuiDataGrid-cell": {
                        backgroundColor: "#f4f4f4",
                        color: "#000",
                      },
                    }}
                  />
                </div>
              ) : (
                <p className="text-gray-500">
                  No history available for this application.
                </p>
              )}
            </div>
          )}
        </div>
      ))}

      {/* Modal for Updating History */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center  justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Update History</h2>
            <label className="block mb-2">
              <span>Status</span>
              <select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                className="w-full p-2 border rounded mt-1"
              >
                <option value="InProgress">InProgress</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Application Criteria Match">Application Criteria Match</option>
                <option value="Document Required">Document Required</option>
                <option value="Rejected">Rejected</option>
              </select>
            </label>
            <label className="block mb-2">
              <span>Comments</span>
              <textarea
                value={newComments}
                onChange={(e) => setNewComments(e.target.value)}
                className="w-full p-2 border rounded mt-1"
              />
            </label>
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="mr-4 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateHistory}
                className="px-4 py-2 bg-gradient-to-r from-secondaryColor to-primaryColor text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSingleLoanApplication;
