import { Routes, Route, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import SignIn from "./pages/authPages/SignIn";
import SignUp from "./pages/authPages/SignUp";
import {
  UserAuthContextProvider,
} from "./context/UserAuthContext";

import AddBank from "./pages/Banks/AddBank";
import Banks from "./pages/Banks/Banks";
import Departments from "./pages/departments/Departments";
import AddDepartment from "./pages/departments/AddDepartment";
import Dashboard from "./pages/Dashboard";
import Employees from "./pages/employees/Employees";
import AddEmploye from "./pages/employees/AddEmploye";
import LoanSchemes from "./pages/loanschemes/LoanSchemes";
import AddLoanScheme from "./pages/loanschemes/AddLoanScheme";
import AddClients from "./pages/clients/AddClients";
import ViewClients from "./pages/clients/ViewClients";
import Applications from "./pages/applications/Applications";
import AddApplications from "./pages/applications/AddApplications";
import FPrograms from "./pages/FPrograms/FPrograms";
import SettingPage from "./pages/SettingPage";
import ViewRole from "./pages/role/ViewRole";
import AddRole from "./pages/role/AddRole";
import { useEffect, useState } from "react";
import { startMonitoring } from "./services/monitoringService";
import ViewPermission from "./pages/permission/ViewPermission";
import AddPermission from "./pages/permission/AddPermission";
import ViewSingleRole from "./pages/role/ViewSingleRole";
import ViewLoanSchemeForm from "./pages/loanScheme/ViewLoanSchemeForm";
import AddLoanSchemeForm from "./pages/loanScheme/AddLoanSchemeForm";
import ViewSingleBank from "./pages/Banks/ViewSingleBank";
import ProtectedRoute from "./components/ProtectedRoute";
import ViewActivities from "./pages/activities/ViewActivities";
import Loggs from "./pages/Loggs/Loggs";
import LoanApplication from "./pages/loanApplication/LoanApplication";
import ViewSingleLoanApplication from "./pages/loanApplication/ViewSingleLoanApplication";
import LoanHistory from "./pages/history/LoanHistory";
import ViewSingleLoanHistory from "./pages/history/ViewSingleLoanHistory";
import AddReferralCompany from "./pages/referral/AddReferralCompany";
import ReferralCompany from "./pages/referral/ReferralCompany";
import ViewSingleLoanSchemeForm from "./pages/loanScheme/ViewSingleLoanSchemeForm";
import ViewSingleClient from "./pages/clients/ViewSingleClient";
import ViewSingleLog from "./pages/Loggs/ViewSingleLog";
import BankEmployee from "./pages/banksEmployee/BankEmployee";
import AddBankEmployee from "./pages/banksEmployee/AddBankEmployee";
import SingleFProgram from "./pages/FPrograms/SingleFProgram";
import UpdateRole from "./pages/role/UpdateRole";
import ViewPrpgramType from "./pages/programType/ViewPrpgramType";
import AddProgramType from "./pages/programType/AddProgramType";


function App() {
  const [consentGiven] = useState(false);

  // Retrieve userId from localStorage
  const storedUserData = JSON.parse(localStorage.getItem("user"));
  const userId = storedUserData?.userId || null;

  console.log(userId);

  useEffect(() => {
    if (consentGiven) {
      console.log("Consent given. User activity logging started.");
      startMonitoring(userId);
    }
  }, [consentGiven, userId]);

  return (
    <UserAuthContextProvider>
      <HashRouter>
        <ToastContainer />

        <Routes>
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="/" element={<Dashboard />} />
            <Route path="settings" element={<SettingPage />} />

            <Route path="program-type" element={<ViewPrpgramType />} />
            <Route path="add-program-type" element={<AddProgramType />} />
            <Route path="banks" element={<Banks />} />
            <Route path="add-bank" element={<AddBank />} />
            <Route path="view-bank/:id" element={<ViewSingleBank />} />

            <Route path="bank-employee" element={<BankEmployee />} />
            <Route path="add-bank-employee" element={<AddBankEmployee />} />

            <Route path="departments" element={<Departments />} />
            <Route path="add-department" element={<AddDepartment />} />
            <Route path="f-programs" element={<FPrograms />} />
            <Route path="single-f-programs" element={<SingleFProgram />} />

            <Route path="loan-history" element={<LoanHistory />} />
            <Route path="view-loan-history/:loanId" element={<ViewSingleLoanHistory />} />

            <Route path="add-broker-company" element={<AddReferralCompany />} />
            <Route path="broker-company" element={<ReferralCompany />} />
         
            <Route path="employees" element={<Employees />} />
            <Route path="add-employee" element={<AddEmploye />} />
            <Route path="loanschemes" element={<LoanSchemes />} />
            <Route path="add-loanscheme" element={<AddLoanScheme />} />
            <Route path="clients" element={<ViewClients />} />
            <Route path="view-client/:nationalID" element={<ViewSingleClient />} />
            <Route path="add-client" element={<AddClients />} />
            <Route path="applications" element={<Applications />} />
            <Route path="add-application" element={<AddApplications />} />
            <Route path="loan-application" element={<LoanApplication />} />
            <Route path="view-loan/:id" element={<ViewSingleLoanApplication />} />
            <Route path="loanprogram" element={<ViewLoanSchemeForm />} />

            <Route path="view-loanprogram/:id" element={<ViewSingleLoanSchemeForm />} />

            <Route path="add-loanprogram" element={<AddLoanSchemeForm />} />
            <Route path="role" element={<ViewRole />} />
            <Route path="add-role" element={<AddRole />} />
            <Route path="view-role/:id" element={<ViewSingleRole />} />
            <Route path="update-role/:id" element={<UpdateRole />} />

            <Route path="permission" element={<ViewPermission />} />
            <Route path="add-permission" element={<AddPermission />} />
            <Route path="view-activities" element={<ViewActivities />} />
            <Route path="logs" element={<Loggs />} />
            <Route path="view-logs/:id" element={<ViewSingleLog />} />
          </Route>
        </Routes>
      </HashRouter>
    </UserAuthContextProvider>
  );
}

export default App;
