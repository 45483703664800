import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const ViewSingleRole = () => {
  const { id } = useParams(); // Get the role ID from the URL
  const [roleData, setRoleData] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const docRef = doc(db, "roles", id);
        const roleDoc = await getDoc(docRef);

        if (roleDoc.exists()) {
          const data = roleDoc.data();
          setRoleData(data);

          // Map permissions into an array for easy rendering
          const formattedPermissions = Object.keys(data.permissions || {}).map(
            (featureName) => ({
              featureName,
              allowed: data.permissions[featureName],
            })
          );

          setPermissions(formattedPermissions);

          // Group permissions by the first part of their feature name
          const grouped = formattedPermissions.reduce((acc, permission) => {
            const group = permission.featureName.split(/(?=[A-Z])/)[0]; // Extract first part (e.g., 'Bank' from 'BankAdd')
            if (!acc[group]) acc[group] = [];
            acc[group].push(permission);
            return acc;
          }, {});

          setGroupedPermissions(grouped);
        } else {
          toast.error("Role not found");
        }
      } catch (error) {
        console.error("Error fetching role: ", error);
        toast.error("Failed to fetch role details.");
      } finally {
        setLoading(false);
      }
    };

    fetchRole();
  }, [id]);

  if (loading) {
    return <div className="loader-container">Loading...</div>;
  }

  if (!roleData) {
    return <p className="text-gray-500">Role details not found.</p>;
  }

  return (
    <div className="rounded-lg shadow p-10 w-[90%] mx-auto">
      <h1 className="font-bold text-xl mb-6">Role Details</h1>

      <div className="flex flex-col gap-4">
        {/* Role Name */}
        <div>
          <label className="font-semibold text-md">Role:</label>
          <p className="text-gray-700 text-lg mt-1">{roleData.role}</p>
        </div>

        {/* Permissions Grouped by Feature */}
        <div>
          <h2 className="font-bold text-lg">Permissions:</h2>
          {Object.keys(groupedPermissions).map((group) => (
            <div key={group} className="border rounded-lg p-4 shadow-sm mt-4">
              <h3 className="font-semibold text-md mb-2">{group}</h3>
              <div className="grid grid-cols-2 gap-4">
                {groupedPermissions[group].map((permission) => (
                  <div key={permission.featureName} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={permission.allowed}
                      readOnly
                      className="form-checkbox"
                    />
                    <label className="font-medium">{permission.featureName}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewSingleRole;
