import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";


const ViewSingleClient = () => {
  const { nationalID } = useParams(); // Get nationalID from the URL
  const [loanApplications, setLoanApplications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanApplications = async () => {
      try {
        const appliedLoanRef = collection(db, "AppliedLoanApplication");
        const q = query(appliedLoanRef, where("nationalID", "==", nationalID));
        const querySnapshot = await getDocs(q);
        const loanData = querySnapshot.docs.map((doc, index) => ({
          serialNo: index + 1,
          id: doc.id,
          ...doc.data(),
        }));
        setLoanApplications(loanData);
       
      } catch (error) {
        setError("Error fetching loan applications. Please try again later.");
        console.error("Error fetching loan applications:", error);
      } finally {
        setLoading(false);
      }
    };

    if (nationalID) {
      fetchLoanApplications();
    }
  }, [nationalID]);



  const columns = [
    { field: "serialNo", headerName: "Serial No", flex: 0.5 , headerClassName: "header"},
    { field: "userId", headerName: "User ID", flex: 1, headerClassName: "header" },
    { field: "loanId", headerName: "Loan ID", flex: 1 , headerClassName: "header"},
    { field: "userName", headerName: "User Name", flex: 1 , headerClassName: "header"},
    { field: "loanProgram", headerName: "Loan Program", flex: 1 , headerClassName: "header"},
    { field: "loanAmount", headerName: "Loan Amount", flex: 1, headerClassName: "header" },
    { field: "approvalStatus", headerName: "Status", flex: 1 , headerClassName: "header"},
   
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl">Loan Applications</h1>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : loanApplications.length === 0 ? (
        <div>No applications found for this National ID.</div>
      ) : (
        <DataGrid
          rows={loanApplications}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default ViewSingleClient;
