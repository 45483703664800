import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash, FaPencilAlt, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";

const Banks = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { permissions } = useSelector((state) => state.permissions);

  const permissionObject = Array.isArray(permissions)
    ? permissions.find((perm) => typeof perm === "object") || {}
    : permissions; // Assuming permissions might already be an object

  const canAdd = permissionObject.BankAdd || false;
  const canEdit = permissionObject.BankUpdate || false;
  const canDelete = permissionObject.BankDelete || false;
 

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const fetchBanks = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Banks"));

      const banks = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      }));
      setData(banks);
    } catch (error) {
      console.error("Error fetching Banks: ", error);
      setError("Failed to load Banks.");
      logUserActivity("User Viewed Banks");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  const handleDelete = async (id) => {
    if (!canDelete) return;
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this bank?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "Banks", id));
        setData(data.filter((item) => item.id !== id));
        toast.success("Bank deleted successfully!");
        logUserActivity("User Deleted Bank");
      } catch (error) {
        console.error("Error deleting bank data: ", error);
        setError("Failed to delete bank data.");
        toast.error("Failed to delete bank data.");
      }
    }
  };

  // Define columns
  const baseColumns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 0.5,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Bank Name",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "headquarters",
      headerName: "Bank Headquarters Location",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      flex: 1,
      headerClassName: "header",
    },
  ];

  if (canEdit || canDelete) {
    baseColumns.push({
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "header",
      renderCell: (params) =>
        params?.row ? (
          <div className="flex items-center gap-2 h-full">
            <Link
              to={`/view-bank/${params.row.id}`} // Replace this path with your actual view route
              className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
            >
              <FaEye className="text-white cursor-pointer h-4 w-4" />{" "}
              {/* View Icon */}
            </Link>
            {canEdit && (
              <Link
                to={`/update-bank/${params.row.id}`} // Adjust path accordingly
                className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8"
              >
                <FaPencilAlt className="text-white cursor-pointer h-4 w-4" />{" "}
                {/* Edit Icon */}
              </Link>
            )}
            {canDelete && (
              <div
                onClick={() => handleDelete(params.row.id)}
                className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
              >
                <FaTrash className="text-white h-4 w-4" /> {/* Delete Icon */}
              </div>
            )}
          </div>
        ) : null,
    });
  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl text-secondaryColor">All Banks</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 py-2 flex gap-2 items-center border-themeColor text-sm font-semibold">
            <Link to="/add-bank">Add Bank</Link>
          </button>
        )}
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No banks found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={baseColumns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default Banks;
