import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const Dashboard = () => {
  const [loanApplications, setLoanApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLoanApplications = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "LoanApplication"));
        const applications = [];
        querySnapshot.forEach((doc) => {
          applications.push({ id: doc.id, ...doc.data() });
        });
        setLoanApplications(applications);
      } catch (error) {
        console.error("Error fetching loan applications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoanApplications();
  }, []);

  // Calculate totals
  const totalPending = loanApplications.filter(
    (application) => application.status === "Pending"
  ).length;
  const totalApproved = loanApplications.filter(
    (application) => application.status === "Approved"
  ).length;
  const totalInProgress = loanApplications.filter(
    (application) => application.status === "In Progress"
  ).length;

  // Calculate bank status counts
  const bankStatusCounts = loanApplications.reduce((acc, application) => {
    application.bankApplications?.forEach((bankApp) => {
      const bank = bankApp.bank || "Unknown Bank";
      const status = bankApp.approvalStatus || "Unknown Status";
      if (!acc[bank]) acc[bank] = { Approved: 0, Pending: 0, Others: 0 };
      if (status === "Approved") acc[bank].Approved++;
      else if (status === "Pending") acc[bank].Pending++;
      else acc[bank].Others++;
    });
    return acc;
  }, {});

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Loan Applications Dashboard</h2>

      {/* Display totals */}
      <div className="mb-6">
        <div className="flex space-x-6">
          <div className="bg-white shadow-md rounded-lg p-4">
            <h3 className="text-lg font-bold text-green-600">
              Total Approved Applications
            </h3>
            <p className="text-xl">{totalApproved}</p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-4">
            <h3 className="text-lg font-bold text-yellow-600">
              Total Pending Applications
            </h3>
            <p className="text-xl">{totalPending}</p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-4">
            <h3 className="text-lg font-bold text-blue-600">
              Total In Progress Applications
            </h3>
            <p className="text-xl">{totalInProgress}</p>
          </div>
        </div>
      </div>

      {/* Display bank status counts */}
      <div className="mb-6">
      <h3 className="text-lg font-bold mb-4">Bank Status Counts</h3>
  <div className="flex flex-wrap -mx-4">
    {Object.entries(bankStatusCounts).map(([bank, counts]) => (
      <>
      
      <div key={bank} className="w-full md:w-1/2 lg:w-1/3 px-4 mb-6">
        <div className="bg-white shadow-md rounded-lg p-4">
          <h4 className="text-lg font-bold mb-2">{bank}</h4>
          <ul>
            <li className="text-sm">
              <strong>Approved:</strong> {counts.Approved}
            </li>
            <li className="text-sm">
              <strong>Pending:</strong> {counts.Pending}
            </li>
            <li className="text-sm">
              <strong>In Progress:</strong> {counts.Others}
            </li>
          </ul>
        </div>
      </div>
      </>
    ))}
  </div>
</div>

    </div>
  );
};

export default Dashboard;
