import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore"; // Import arrayUnion
import { db } from "../../firebase";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import SelectField from "../../components/fields/SelectField";
import InputField from "../../components/fields/TextInput";


const ViewSingleLoanHistory = () => {
  const { loanId } = useParams(); // Extract loanId from the URL
  const [loanData, setLoanData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newEntry, setNewEntry] = useState({
    document_action: "",
    status: "",
    comments: "",
    timestamp: new Date().toISOString(),
  });

  useEffect(() => {
    const fetchLoanDetails = async () => {
      try {
        if (!loanId) throw new Error("Loan ID not provided.");

        const loanDocRef = doc(db, "LoanHistories", loanId);
        const loanDoc = await getDoc(loanDocRef);

        if (loanDoc.exists()) {
          const loanHistory = loanDoc.data().loanHistory || [];
          setLoanData({ ...loanDoc.data(), loanHistory });
          
        } else {
          setError("Loan history not found.");
        }
      } catch (error) {
        setError(error.message || "Error fetching loan details.");
        console.error("Error fetching loan details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLoanDetails();
  }, [loanId]);

  const handleInputChange = (field, value) => {
    setNewEntry((prevEntry) => ({ ...prevEntry, [field]: value }));
  };

  const handleSave = async () => {
    try {
      const loanDocRef = doc(db, "LoanHistories", loanId);
      await updateDoc(loanDocRef, {
        loanHistory: arrayUnion(newEntry), // Add new entry without overwriting
      });

      setLoanData((prevData) => ({
        ...prevData,
        loanHistory: [...prevData.loanHistory, newEntry],
      }));

      alert("New loan history entry added successfully.");
      setNewEntry({
        document_action: "",
        status: "",
        comments: "",
        timestamp: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error adding loan history entry:", error);
      alert("Error adding loan history entry. Please try again.");
    }
  };

  if (loading) {
    return <div className="loader-container">Loading...</div>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (!loanData) {
    return (
      <div className="flex flex-col items-center justify-center h-[80vh]">
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }

  const { loanHistory = [] } = loanData;

  // Define options for the status dropdown
  const statusOptions = [
    { id: "Pending", name: "Pending" },
    { id: "Approved", name: "Approved" },
  ];

  return (
    <div className="p-6">
      <Link to="/loan-history" className="flex items-center text-blue-500 mb-4">
        <FaArrowLeft className="mr-2" />
        Back to Loan History
      </Link>
      <h1 className="font-bold text-2xl mb-4">Loan History</h1>

      <div className="bg-white shadow-lg rounded-xl py-4 px-8">
        <h2 className="text-xl font-semibold text-secondaryColor">Loan ID: {loanId}</h2>
        <p className="text-primaryColor mt-2">
          Number of Entries: {loanHistory.length}
        </p>

        <div className="mt-4">
          {loanHistory.map((entry, index) => (
            <div key={index} className="border-b border-gray-300 py-3">
              <div className="flex justify-between gap-6">
                <p>
                  <strong>Action:</strong> {entry.document_action}
                </p>
                <p>
                  <strong>Status:</strong> {entry.status}
                </p>
              </div>

              <div className="flex justify-between gap-6 my-2">
                <p>
                  <strong>Comments:</strong> {entry.comments}
                </p>
                <p>
                  <strong>Timestamp:</strong>{" "}
                  {new Date(entry.timestamp).toLocaleString()}
                </p>
              </div>
            </div>
          ))}
        </div>

        <h3 className="font-bold text-xl mt-6">Add New Entry</h3>
        <div className="flex justify-between gap-6">
          <div className="w-full">
            <InputField
              label="Action"
              value={newEntry.document_action}
              onChange={(e) =>
                handleInputChange("document_action", e.target.value)
              }
            />
          </div>

          <div className="w-full">
            <SelectField
              label="Status"
              options={statusOptions}
              register={() => {}} // Add register function if using form validation (e.g. react-hook-form)
              name="status"
              error={null} // If using validation, pass the error message
              onChange={(e) => handleInputChange("status", e.target.value)}
            />
          </div>
        </div>

        <div className="mt-4">
          <label>
            Comments:
            <textarea
              value={newEntry.comments}
              onChange={(e) => handleInputChange("comments", e.target.value)}
              className="block border rounded w-full p-1 mt-1"
            />
          </label>
        </div>

        <button
          onClick={handleSave}
          className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6 flex items-center"
        >
          <FaSave className="mr-2" /> Save New Entry
        </button>
      </div>
    </div>
  );
};

export default ViewSingleLoanHistory;
