import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";


const UpdateRole = () => {
  const { id } = useParams(); // Get the role ID from the URL
  const [roleData, setRoleData] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const docRef = doc(db, "roles", id);
        const roleDoc = await getDoc(docRef);

        if (roleDoc.exists()) {
          const data = roleDoc.data();
          setRoleData(data);

          // Initialize selectedPermissions based on current data
          setSelectedPermissions(data.permissions || {});
          
          // Map permissions for rendering and group them
          const formattedPermissions = Object.keys(data.permissions || {}).map(
            (featureName) => ({
              featureName,
              allowed: data.permissions[featureName],
            })
          );
          setPermissions(formattedPermissions);

          // Group permissions by the first part of their feature name
          const grouped = formattedPermissions.reduce((acc, permission) => {
            const group = permission.featureName.split(/(?=[A-Z])/)[0]; // Extract first part (e.g., 'Bank' from 'BankAdd')
            if (!acc[group]) acc[group] = [];
            acc[group].push(permission);
            return acc;
          }, {});

          setGroupedPermissions(grouped);
        } else {
          toast.error("Role not found");
        }
      } catch (error) {
        console.error("Error fetching role: ", error);
        toast.error("Failed to fetch role details.");
      } finally {
        setLoading(false);
      }
    };

    fetchRole();
  }, [id]);

  const handleCheckboxChange = (featureName) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [featureName]: !prev[featureName], // Toggle the value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare the updated role data with only permissions
      const updatedPermissions = {
        permissions: selectedPermissions,
      };

      // Update the permissions in Firestore
      const docRef = doc(db, "roles", id);
      await updateDoc(docRef, updatedPermissions);
      toast.success("Permissions updated successfully!");
      navigate(`/view-role/${id}`);
    } catch (error) {
      console.error("Error updating permissions: ", error);
      toast.error("Failed to update permissions.");
    }
  };

  if (loading) {
    return <div className="loader-container">Loading...</div>;
  }

  if (!roleData) {
    return <p className="text-gray-500">Role details not found.</p>;
  }

  return (
    <div className="rounded-lg shadow p-10 w-[90%] mx-auto">
      <h1 className="font-bold text-xl mb-6">Update Role Permissions</h1>

      <form onSubmit={handleSubmit}>
        {/* Role Name (read-only) */}
        <div>
         
         <h1 className="text-2xl text-secondaryColor">Role: {roleData.role}</h1>
        </div>

        {/* Permissions Grouped by Feature */}
        <div className="mt-4">
          <h2 className="font-bold text-lg mb-4">Permissions</h2>
          {Object.keys(groupedPermissions).map((group) => (
            <div key={group} className="border rounded-lg p-4 shadow-sm mt-4">
              <h3 className="font-semibold text-md mb-2">{group}</h3>
              <div className="grid grid-cols-2 gap-4">
                {groupedPermissions[group].map((permission) => (
                  <div key={permission.featureName} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={selectedPermissions[permission.featureName] || false}
                      onChange={() => handleCheckboxChange(permission.featureName)}
                      className="form-checkbox"
                    />
                    <label className="font-medium">{permission.featureName}</label>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <button
          type="submit"
          className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
        >
          Update Permissions
        </button>
      </form>
    </div>
  );
};

export default UpdateRole;
