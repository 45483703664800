import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../components/fields/TextInput";
import { z } from "zod";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { logUserActivity } from "../../services/logUserActivity";
import { useNavigate } from "react-router-dom";

const dataSchema = z.object({
  programtype: z
    .string()
    .min(1, { message: "programtype is required" })
    .max(30, { message: "programtype cannot exceed 30 characters" }),
});

const AddProgramType = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(dataSchema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "ProgramType"), data);
      console.log("Document written with ID: ", docRef.id);
      toast("Successfully added!");
      logUserActivity("User Added ProgramType");
      navigate("/program-type");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <div>
        <h1 className="font-bold text-xl">Create ProgramType</h1>
      </div>

      <div className="rounded-lg shadow">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[90%] mx-auto flex flex-col gap-4 p-10">
            <InputField
              label="ProgramType"
              error={errors.programtype?.message}
              register={register}
              name="programtype"
               placeholder="Enter ProgramType"
            />

            <button
              type="submit"
              className="text-white bg-gradient-to-r from-secondaryColor to-primaryColor rounded-lg py-2 px-6 my-6"
            >
              Add ProgramType
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddProgramType;
