import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { logUserActivity } from "../../services/logUserActivity";
import { fetchPermissions } from "../../redux/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewPrpgramType = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.permissions);

  const permissionObject = Array.isArray(permissions)
  ? permissions.find((perm) => typeof perm === "object") || {}
  : permissions; // Assuming permissions might already be an object

const canAdd = permissionObject.ServiceAdd || false;
const canEdit = permissionObject.ServiceUpdate || false;
const canDelete = permissionObject.ServiceDelete || false;

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);

  const columns = [
    {
      field: "serialNo",
      headerName: "Serial No",
      flex: 1,
      headerClassName: "header",
    },
    {
      field: "programtype",
      headerName: "Program Type",
      flex: 1,
      headerClassName: "header",
    },
    ...(canEdit || canDelete
      ? [
          {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerClassName: "header",
            renderCell: (params) => (
              <div className="flex items-center gap-2 h-full">
                
                {canDelete && (
                  <div
                    onClick={() => handleDelete(params.row.id)}
                    className="flex items-center justify-center rounded-full bg-secondaryColor h-8 w-8 cursor-pointer"
                  >
                    <FaTrash className="text-white h-4 w-4" />
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "ProgramType"));
        const products = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setData(products);
        logUserActivity("User Viewed Program Type");
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    if (!canDelete) return; // Restrict deletion if not permitted

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this service?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "ProgramType", id));
        setData(data.filter((item) => item.id !== id));
        logUserActivity("User Deleted ProgramType");
      } catch (error) {
        console.error("Error deleting data: ", error);
        setError("Failed to delete data.");
      }
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="font-bold text-xl">Program Types</h1>
        {canAdd && (
          <button className="bg-secondaryColor text-white hover:bg-primaryColor border rounded-xl px-4 flex py-2 gap-2 items-center border-themeColor text-sm font-semibold cursor-pointer">
            <Link to="/add-program-type">Add Program Type</Link>
          </button>
        )}
      </div>

      {loading ? (
        <div className="loader-container">Loading...</div>
      ) : error ? (
        <p>{error}</p>
      ) : data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-[80vh]">
          <p className="text-gray-500">No data found</p>
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .header": {
              backgroundColor: "#214783",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#f4f4f4",
              color: "#000",
            },
          }}
        />
      )}
    </div>
  );
};

export default ViewPrpgramType;
